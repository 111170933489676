import React from "react"

import Images from "../../img/shape-1.png"

const Whoweare = () => {
  return (
    <>
      <section>
        <div className="rs-choose choose-style1">
          <div className="row no-gutters">
            <div className="col-lg-6 md-mb-50 video-item">
              <div className="rs-videos">
                <div className="animate-border">
                  <a
                    className="popup-border popup-videos"
                    href="https://www.youtube.com/watch?v=FMvA5fyZ338"
                  >
                    <i className="fa fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="widget-wrap-content">
                <div className="sec-title mb-46 md-mb-25">
                  <span className="sub-text">
                    <img src={Images} alt="Images" />
                    Why Choose Us
                  </span>
                  <h2 className="title pb-20">
                    Why you should choose
                    <br />
                    our Bradiance platform.
                  </h2>
                  <p className="desc">
                    Nor is there anyone who loves or pursues or desires to
                    obtain pain of the
                    <br />
                    itself, because it is pain, but occasionally cumstances.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-25">
                    <div className="rs-addon-services">
                      <div className="services-item">
                        <div className="serial-number">1</div>
                        <div className="services-text">
                          <div className="services-title">
                            <h4 className="title">Best sustainability</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-25">
                    <div className="rs-addon-services">
                      <div className="services-item">
                        <div className="serial-number">2</div>
                        <div className="services-text">
                          <div className="services-title">
                            <h4 className="title">Health and safety</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 md-mb-25">
                    <div className="rs-addon-services">
                      <div className="services-item">
                        <div className="serial-number">3</div>
                        <div className="services-text">
                          <div className="services-title">
                            <h4 className="title">Modern methods</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="rs-addon-services">
                      <div className="services-item">
                        <div className="serial-number">4</div>
                        <div className="services-text">
                          <div className="services-title">
                            <h4 className="title">Certified engineer</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="working-skill-part pt-50">
                  <div className="row y-middle">
                    <div className="col-lg-4">
                      <div className="title-inner mb-10">
                        <h4 className="title">Working skill</h4>
                      </div>
                      <div className="title-inner">
                        <h4 className="title">Client rating</h4>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="rs-skillbar skillbar-style1 mb-10">
                        <div className="cl-skill-bar">
                          <div className="skillbar" data-percent="95">
                            <p className="skillbar-bar skill-icon"></p>
                            <span className="skill-bar-percent">95%</span>
                          </div>
                        </div>
                      </div>
                      <ul className="icon-list-item">
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p className="view-result pt-15">
                    5.00 Average Based On 50 Rating –{" "}
                    <a href="/">
                      <span>More information</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Whoweare
