import React from "react"

import Images from "../../img/shape-1.png"

const Whoweare = () => {
  return (
    <>
      <section>
        <div className="rs-counter counter-style1 bg2 pt-120 pb-120 md-pt-80 md-pb-80 efficient">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-50">
                <div className="sec-title">
                  <span className="sub-text yellow-color">
                    <img src={Images} alt="Images" />
                    Number talks
                  </span>
                  <h2 className="title white-color pb-20">
                    Bradiance will help you get
                    <br />
                    things complete reality.
                  </h2>
                  <p className="desc desc2 pb-45">
                    Bradiance will help you get things complete reality.
                  </p>
                  <div className="btn-part">
                    <a className="readon more contact" href="contact-us/">
                      Contact Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-xl-6 col-md-6 col-sm-6 mb-30 numbertalks">
                    <div className="rs-counter-list">
                      <div className="count-text">
                        <div className="count-number">
                          <span className="rs-count">341</span>
                          <span className="prefix">+</span>
                        </div>
                        <span className="title"> Completed Projects</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-6 mb-30 numbertalks">
                    <div className="rs-counter-list">
                      <div className="count-text">
                        <div className="count-number">
                          <span className="rs-count">302</span>
                          <span className="prefix">+</span>
                        </div>
                        <span className="title">Happy Clients</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-6 xs-mb-30 numbertalks">
                    <div className="rs-counter-list">
                      <div className="count-text">
                        <div className="count-number">
                          <span className="rs-count">153</span>
                          <span className="prefix">+</span>
                        </div>
                        <span className="title">Qualified Engineers</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-6 col-sm-6">
                    <div className="rs-counter-list">
                      <div className="count-text">
                        <div className="count-number">
                          <span className="rs-count">32</span>
                          <span className="prefix">+</span>
                        </div>
                        <span className="title">Years Experiance</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Whoweare
