import React from "react"
import Slider from "./slider"
import Whoweare from "./who-we-are"
import Whatwedo from "./what-we-do"
import Numbertalk from "./number-talks"
import Yourbenefit from "./your-benefit"
import Whychooseus from "./why-choose-us"

import Letstalk from "./letstalk"
import WorkWithUs from "./work-with-us"

const homePage = () => {
  return (
    <>
      
      <Whoweare />
      <Whatwedo />
      <Numbertalk />
      <Yourbenefit />
      <Whychooseus />

      <Letstalk />
      <WorkWithUs />
    </>
  )
}

export default homePage
