import React from "react"

import Images from "../../img/shape-1.png"

const Whoweare = () => {
  return (
    <>
      <section>
        <div className="rs-cta bg4 pt-120 pb-115 md-pt-80 md-pb-75 efficient">
          <div className="container">
            <div className="call-action">
              <div className="sec-title text-center">
                <span className="sub-text yellow-color">
                  <img src={Images} alt="Images" />
                  Work With Us !
                </span>
                <h2 className="title white-color pb-20">
                  Have any upcoming project
                </h2>
                <p className="desc desc3 pb-35">
                  We provide services for required project deliverables based on
                  one-time pricing <br />
                  or contract basis. We always strive to provide exceptional
                  service with remarkable quality.
                </p>
                <div className="btn-part">
                  <a className="readon more contact" href="contact-us/">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Whoweare
