import React from "react"

import servicesone from "../../img/servic-1.png"
import servicesoneicon from "../../img/services/main-home/main-icons/service-icon1.png"
import Images from "../../img/shape-1.png"
import { useStaticQuery, Link, graphql } from "gatsby"

const Whoweare = () => {
  const allServicesFromAPI = useStaticQuery(graphql`
    query {
      allMysqlCategories {
        nodes {
          category_id
          description
          image_url
          title
          slug
        }
      }
    }
  `)
  const categoriesArray = allServicesFromAPI.allMysqlCategories.nodes

  return (
    <>
      <div className="rs-services services-main-home gray-bg pt-120 pb-120 md-pt-80 md-pb-80 efficient">
        <div className="container">
          <div className="sec-title text-center mb-55 md-mb-35">
            <span className="sub-text">
              <img src={Images} alt="Images" />
              What We Do
            </span>
            <h2 className="title">
              Develop comprehensive solutions
              <br />
              for each project
            </h2>
          </div>
          <div className="row">
            {categoriesArray.map((item, index) => (
              <div className="col-xl-4 col-md-6 mb-30" key={index}>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-image">
                      <img src={servicesone} alt="Services" />
                    </div>
                    <div className="services-content">
                      <div className="service-inner">
                        <div className="icon-top">
                          <img src={servicesoneicon} alt="Services" />
                        </div>
                        <div className="services-titles">
                          <h3 className="title">
                            <a href="general-construction">
                              {item.description}
                            </a>
                          </h3>
                        </div>
                        <p className="services-txt">
                          {item.description.split(" ").splice(0, 25).join(" ")}
                        </p>
                        <div className="services-btn">
                          <Link to={item.slug} className="btn-text">
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="normarl-text-area">
                      <div className="services-titles">
                        <h3 className="title">
                          <Link to={item.slug}>{item.title}</Link>
                        </h3>
                      </div>
                      <div className="icon-image">
                        <img src={servicesoneicon} alt="Services" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Whoweare
