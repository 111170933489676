import React from "react"

import Images from "../../img/shape-1.png"

const Whoweare = () => {
  return (
    <>
      <section>
        <div className="rs-faq faq-style1">
          <div className="row">
            <div className="col-lg-4 offset-2 faq-offset6 md-mb-50">
              <div className="faq-content">
                <div className="sec-title mb-40">
                  <span className="sub-text">
                    <img src={Images} alt="Images" />
                    Your Benefit
                  </span>
                  <h2 className="title">
                    Build better futures with
                    <br />
                    steady focus on quality
                  </h2>
                </div>
                <div id="accordion" className="accordion">
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="card-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        href="collapse"
                      >
                        We are a global family that values diversity.
                      </a>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse show"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body">
                        Nor is there anyone who loves or pursues or desires to
                        obtain pain of itself, because it is pain, but
                        occasionally cumstances occur in which toil and pain can
                        procuresteady steady.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="card-link collapsed"
                        href="\"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                      >
                        We own and shape our future.
                      </a>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body">
                        Nor is there anyone who loves or pursues or desires to
                        obtain pain of itself, because it is pain, but
                        occasionally cumstances occur in which toil and pain can
                        procuresteady steady.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="card-link collapsed last-margin"
                        href="\"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                      >
                        We create sustainable growth.
                      </a>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="card-body">
                        Nor is there anyone who loves or pursues or desires to
                        obtain pain of itself, because it is pain, but
                        occasionally cumstances occur in which toil and pain can
                        procuresteady steady.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 faq-images md-offset-2"></div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Whoweare
