import React from "react"

import Management from "../../img/about/style1/about.png"
import Efficient from "../../img/about/style1/abzt.jpg"
import Images from "../../img/shape-1.png"

const Whoweare = () => {
  return (
    <>
      <section>
        <div className="rs-about about-style1 bg1 pt-120 pb-120 md-pt-80 md-pb-80 efficient">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6 md-mb-50">
                <div className="images-part">
                  <img src={Management} alt="About" />
                </div>
              </div>
              <div className="col-lg-6 pl-50 md-pl-15">
                <div className="sec-title">
                  <span className="sub-text">
                    <img src={Images} alt="Images" />
                    Who We Are
                  </span>
                  <h2 className="title pb-20">Efficient Project Management</h2>
                  <p className="desc pb-37">
                    Project management consultants with extensive experience in
                    providing project management services for small, medium and
                    capital projects. We specializes in EPC projects management,
                    Business management and Energy management. We have
                    collaboriations with University of British Columbia,
                    Vancouver, Cananda and IIT, Chennai India.
                  </p>
                  <div className="row y-middle">
                    <div className="col-lg-4 col-md-4 sm-mb-30">
                      <div className="about-img">
                        <img src={Efficient} alt="About" />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                      <ul className="check-lists">
                        <li className="list-item">
                          <span className="icon-list-icon">
                            <i className="fa fa-check-circle"></i>
                          </span>
                          <span className="list-text">Strength 1</span>
                        </li>
                        <li className="list-item">
                          <span className="icon-list-icon">
                            <i className="fa fa-check-circle"></i>
                          </span>
                          <span className="list-text">Strength 2</span>
                        </li>
                        <li className="list-item">
                          <span className="icon-list-icon">
                            <i className="fa fa-check-circle"></i>
                          </span>
                          <span className="list-text">Strength 3</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row mt-47 md-mt-25">
                    <div className="col-lg-6 col-md-4 sm-mb-30">
                      <div className="btn-part">
                        <a className="readon more know" href="/">
                          Know more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Whoweare
