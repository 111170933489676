import React from "react"

import Layout from "../components/layout"
import Home from "../components/home/index"

import Seo from "../components/seo"

const homePage = () => (
  <Layout>
    <Seo title="Bradiance" desc="Bradiance" keywords="Bradiance" />
    <Home />
  </Layout>
)

export default homePage
