import React from "react"

import contacticonone from "../../img/contact/style1/icons/1.png"
import contacticontwo from "../../img/contact/style1/icons/2.png"
import contacticonthree from "../../img/contact/style1/icons/3.png"
import Images from "../../img/shape-1.png"

const Whoweare = () => {
  return (
    <>
      <section>
        <div className="rs-contact contact-style1 bg3">
          <div className="row">
            <div className="col-lg-4 offset-2 con-left-6">
              <div className="contact-section">
                <div className="contact-wrap">
                  <div className="sec-title mb-45">
                    <span className="sub-text">
                      <img src={Images} alt="Images" />
                      Lets talk
                    </span>
                    <h2 className="title">Have an upcoming project?</h2>
                  </div>
                  <div id="form-messages"></div>
                  <form id="contact-form" method="post" action="mailer.php">
                    <fieldset>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4 letstalk-contact">
                          <input
                            className="from-control"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name"
                            required=""
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                          <input
                            className="from-control"
                            type="text"
                            id="email"
                            name="email"
                            placeholder="E-Mail"
                            required=""
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                          <input
                            className="from-control"
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Phone Number"
                            required=""
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                          <input
                            className="from-control"
                            type="text"
                            id="website"
                            name="website"
                            placeholder="Your Website"
                            required=""
                          />
                        </div>
                        <div className="col-lg-12 mb-4">
                          <textarea
                            className="from-control"
                            id="message"
                            name="message"
                            placeholder="Your Message Here"
                            required=""
                          ></textarea>
                        </div>
                      </div>
                      <div className="btn-part">
                        <div className="form-group mb-4">
                          <input
                            className="readon more submit"
                            type="submit"
                            value="Submit Now"
                          />
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-md-5 con-right-6 md-mt-5">
              <div className="contact-box-item">
                <div className="contact-box mb-3">
                  <div className="contact-icon">
                    <img src={contacticonone} alt="images" />
                  </div>
                  <div className="content-text">
                    <h4 className="title">
                      <a href="/">Our office</a>
                    </h4>
                    <p className="services-txt">
                      3192 East 49th Avenue #6545 <br />
                      Vancouver, BC V5S 1L1
                      <br />
                      Canada
                    </p>
                  </div>
                </div>
                <div className="contact-box mb-3">
                  <div className="contact-icon">
                    <img src={contacticontwo} alt="images" />
                  </div>
                  <div className="content-text">
                    <h4 className="title">
                      <a href="/">Email us</a>
                    </h4>
                    <p className="services-txt">
                      <a href="mailto:info@bradiancepmc.com">
                        info@bradiancepmc.com
                      </a>
                    </p>
                  </div>
                </div>
                <div className="contact-box mb-3">
                  <div className="contact-icon">
                    <img src={contacticonthree} alt="images" />
                  </div>
                  <div className="content-text">
                    <h4 className="title">
                      <a href="/">Call us</a>
                    </h4>
                    <p className="services-txt">
                      <a href="tel:(604)446-9239">(604) 446-9239</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Whoweare
